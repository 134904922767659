#about {
    .second {
        display: flex;
        padding: 0px 0px 0 125px;

        p {
            font-weight: 400;
            font-size: 20px;
            color: #999999;
            max-width: 80%;
        }
    }

    @media only screen and (max-width: 1550px) {
        .second {
            padding: 20px 40px;
            text-align: center;
            justify-content: center;
            width: 100%;

            p {
                max-width: 100%; 
            }
        }
    }
}