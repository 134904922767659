#contact {
    .entry.welcome .image {
        $circle-min: 120px;
        $circle-gap: 65px;
        display: flex;
        align-items: center;
        
        .layer-1 {
            width: $circle-min + $circle-gap * 2;
            height: $circle-min + $circle-gap * 2;

            .layer-2 {
                width: $circle-min + $circle-gap;
                height: $circle-min + $circle-gap;

                .layer-3 {
                    width: $circle-min;
                    height: $circle-min;

                    img {
                        width: $circle-min;
                        height: $circle-min;
                    }
                }
            }
        }
    }

    .contact-form-container {
        width: 100%;
        padding: 0 130px;
        margin-top: 50px;

        .contact-form {
            background: rgba(251, 194, 43, 0.75);
            border-radius: 15px;
            padding: 50px 60px;
            margin: 0 auto;
            display: flex;

            .info {
                width: 30%;

                h2 {
                    font-weight: 800;
                    font-size: 48px;
                    line-height: 72px;
                    color: #EEEEEE;
                    word-wrap: break-word;
                }
                
                .contact-infos {
                    margin-top: 90px;

                    > div, > a {
                        display: flex;
                        margin-bottom: 10px;
                        padding: 24px;
                        width: fit-content;

                        &.primary {
                            background: rgba(255, 255, 255, 0.3);
                            border: 3px solid #FFFFFF;
                            border-radius: 16px;
                            color: #ffffff;
                        }

                        img {
                            margin-right: 10px;
                        }

                        p {
                            font-weight: 500;
                            font-size: 20px;
                            word-break: break-all;
                        }
                    }
                }

                .social-medias {
                    margin-top: 100px;
                    display: flex;
                    justify-content: center;

                    a {
                        margin: 0 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:nth-child(2) {
                            background: #FFFFFF;
                            border-radius: 100%;
                            width: 64px;
                            aspect-ratio: 1;
                        }
                    }
                }
            }

            .form-window {
                width: 70%;
                display: flex;
                justify-content: flex-end;

                > div {
                    background: #EEEEEE;
                    border-radius: 24px;
                    height: 100%;
                    width: 70%;
                    padding: 48px;

                    p {
                        font-weight: 500;
                        font-size: 20px;
                        color: #000;
                        margin-bottom:16px;
                        margin-left: 8px;
                    }

                    .topics {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 80px;

                        > div {
                            color: rgba(0, 0, 0, 0.3);
                            font-weight: 500;
                            font-size: 20px;
                            border: 2px solid rgba(0, 0, 0, 0.3);
                            border-radius: 8px;
                            padding: 16px 12px;
                            width: fit-content;
                            margin: 8px;
                            cursor: pointer;

                            //Special case
                            &:first-child {
                                padding: 16px 80px;
                            }

                            &:hover {
                                background: rgba(0, 0, 0, 0.084);
                            }

                            &.selected {
                                color: #000000;
                                background: #FBC22B;
                                border-color: #FBC22B;
                            }
                        }
                    }

                    input {
                        display: block;
                        margin-bottom: 50px;
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        border-bottom: 3px solid rgba(0, 0, 0, 0.5);
                        color: rgba(0, 0, 0, 0.5);
                        font-weight: 500;
                        font-size: 20px;
                        padding-bottom: 6px;
                        outline: none;

                        &:focus {
                            border-color: white;
                            color: #FBC22B;
                        }
                    }

                    a {
                        width: fit-content;
                        padding: 20px 50px;
                        background: #FFFFFF;
                        border-radius: 16px;
                        color: black;
                        font-weight: 600;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            background-color: #FBC22B;
                        }

                        img {
                            margin-right: 16px;
                        }
                    }
                }
            }
        }
    }

    .ending {
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #999999;
            width: 80%;
            margin: 85px auto 40px auto;
        }

        button {
            background: #FBC22B;
            border-radius: 5px;
            color: #000000;
            font-weight: 600;
            font-size: 20px;
            height: 60px;
            width: 200px;
            margin: 0 auto;
            display: block;

            &:hover {
                background-color: #f9b301;
            }

            img {
                margin-right: 10px;
            }
        }
    }

    @media only screen and (max-width: 1526px) {
        .contact-form-container {
            .contact-form {
                .info {
                    width: 40%;
                }
                .form-window {
                    width: 60%;

                    > div {
                        width: 90%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1138px) {
        .contact-form-container {
            .contact-form {
                flex-direction: column;
                padding: 20px;

                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100% !important;

                    &.info {
                        h2 {
                            text-align: center;
                        }

                        .contact-infos {
                            margin-top: 20px;
                        }

                        .social-medias {
                            margin: 20px 0 30px 0;
                        }
                    }

                    &.form-window {
                        > div {
                            width: 95%;
                        }

                        .topics {
                            > div:first-child {
                                padding: 16px 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .contact-form-container {
            padding: 0 30px;

            .form-window {
                a {
                    margin: 0 auto;
                    padding: 13px 25px !important;
                }
            }
        }
    }
}