header {
    background-color: #0F0F0F;
    padding: 0 90px 0 20px;
    position: relative;
    z-index: 100;

    > .container {
        max-width: 1980px;
        height: 140px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        > div {
            height: 100%;
            display: flex;
            align-items: center;
        }
    
        .brand {
            img {
                height: 85px;
                margin-right: 20px;
            }
    
            h1 {
                font-weight: 600;
                color: #FBC22B;
                font-size: 26px;
    
                span {
                    color: white;
                }
            }
        }
    
        .links {
            .mobile {
                display: none;
            }

            a {
                font-weight: 600;
                margin-left: 40px;
                font-size: 20px;
    
                &:hover {
                    color: #FBC22B;
                }
    
                &.contact {
                    color: #FBC22B;
                    border: 2px solid #FBC22B;
                    border-radius: 5px;
                    padding: 15px;
                    transition: 300ms;
    
                    &:hover {
                        background-color: #FBC22B;
                        color: black;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1290px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 1000px) {
        > .container .links {
            > a {
                display: none;
            }

            .mobile {
                display: block;
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        > .container {
            height: 100px;

            .brand img {
                height: 70px;
            }
        }
    }

    @media only screen and (max-width: 515px) {
        > .container .brand h1 {
            display: none;
        }
    }
}