//The welcome section is common for multiple pages
.entry.welcome {
    display: flex;
    padding: 50px 0px 25px 125px;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .text {
        width: 60%;

        .coming-soon {
            font-weight: 600;
            font-size: 36px;
            margin-bottom: 10px;
            color: white;
        }

        .title {
            font-weight: 600;
            color: #FBC22B;
            font-size: 52px;
            margin-bottom: 10px;

            span {
                color: white;
            }
        }

        hr {
            border: 2px solid #999999;
            border-radius: 2px;
            width: 76px;
            margin: 20px 0;
        }

        p {
            font-weight: 400;
            font-size: 20px;
            color: #999999;
            max-width: 90%;

            &.services-explanation {
                font-weight: 500;
            }
        }

        .buttons {
            margin-top: 60px;
            display: flex;
            
            button {
                background: #FBC22B;
                border-radius: 5px;
                color: #000000;
                font-weight: 600;
                font-size: 20px;
                height: 60px;
                width: 200px;
                margin-right: 30px;

                &:hover {
                    background-color: #f9b301;
                }

                img {
                    margin-right: 10px;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                font-weight: 600;
                font-size: 20px;
                color: white;
                width: 200px;
                height: 60px;

                &:hover {
                    background-color: white;
                    color: black;
                }
            }
        }
    }

    .image {
        width: 40%;
        margin-top: 0px;
        position: relative;

        //Circle
        div:not(.layer-1) {
            border-radius: 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50%;
            transform: translateY(-50%);
        }

        $circle-min: 255px;
        $circle-gap: 150px;

        .layer-1 {
            z-index: 1;
            width: $circle-min + $circle-gap * 2;
            height: $circle-min + $circle-gap * 2;
            background: rgb(76, 59, 13);
            position: relative;
            top: 0;
            border-radius: 100%;
            right: 0;

            .layer-2 {
                z-index: 2;
                width: $circle-min + $circle-gap;
                height: $circle-min + $circle-gap;
                background: rgba(251, 194, 43, 0.4);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                
                .layer-3 {
                    z-index: 3;
                    width: $circle-min;
                    height: $circle-min;
                    background: rgba(255, 255, 255, 0.5);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                    img {
                        width: $circle-min;
                        height: $circle-min;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1550px) {
        padding: 50px 40px 0 40px;

        .text {
            width: 100%;
            text-align: center;
            align-items: center;
        }

        .image {
            width: 100%;
            left: 0;
            position: absolute;
            z-index: -1;
            opacity: 0.2 !important;

            .layer-1 {
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    @media only screen and (max-width: 582px) {
        $circle-min: 150px;
        $circle-gap: 100px;

        .image {
            .layer-1 {
                width: $circle-min + $circle-gap * 2;
                height: $circle-min + $circle-gap * 2;
    
                .layer-2 {
                    z-index: 2;
                    width: $circle-min + $circle-gap;
                    height: $circle-min + $circle-gap;
                    
                    .layer-3 {
                        z-index: 3;
                        width: $circle-min;
                        height: $circle-min;
    
                        img {
                            width: $circle-min;
                            height: $circle-min;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 515px) {
        .text .buttons {
            flex-direction: column;

            button {
                margin-bottom: 20px;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .text .title span {
            display: block;
            margin-top: -20px;
        }
    }
}

#home {
    .technologies {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .background-text {
            position: absolute;
            white-space: nowrap;
            font-family: 'Poppins';
            color: rgba(153, 153, 153, 0.6);
            width: fit-content;
            font-weight: 600;
            font-size: 200px;
            left: -50%;
            user-select: none;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px;
        }

        .main-text {
            font-weight: 600;
            font-size: 36px;
            text-decoration: underline;
            text-align: center;
            position: relative;
            margin-top: 120px;
            z-index: 3;
        }

        .tech-list {
            margin-top: 120px;
            width: 80%;

            .item {
                display: flex;
                justify-content: center;

                > div {
                    background: #323131;
                    border-radius: 30px;
                    width: 200px;
                    height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 4px solid rgb(65, 65, 65);
                    padding: 30px;
    
                    img {
                        filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.1)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.1)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.1)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802));
                    }
                }
            }

            .arrow {
                position: absolute;
                border-radius: 3px;
                font-size: 26px;
                margin-top: -15px;
                top: 50%;
                transform: translateY(-50%);
                background: rgb(25,25,25);
                border-radius: 15px;
                border: 4px solid rgb(39,39,39);
                width: 58px;
                height: 65px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.next {
                    right: -90px;
                }
                
                &.prev {
                    left: -90px;
                }
            }
        }
    }

    @media only screen and (max-width: 966px) {
        .technologies .tech-list {
            .arrow {
                &.next {
                    right: -7.5%;
                }
                &.prev {
                    left: -7.5%;
                }
            }

            .item > div {
                width: 140px;
                height: 140px;

                img {
                    max-height: 100px;
                }
            }
        }
    }
}