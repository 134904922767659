footer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;

    .top-container {
        background: #131313;
        border: 1px solid rgba(255, 255, 255, 0.05);
        border-radius: 20px;
        height: 180px;
        width: calc(100% - 210px);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px;

        h4 {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        p {
            color: #999999;
            font-weight: 400;
            font-size: 16px;
        }

        .buttons {
            display: flex;
            align-items: center;

            a.portfolio {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                font-weight: 600;
                font-size: 20px;
                color: white;
                width: 200px;
                height: 60px;
                margin-right: 40px;
    
                &:hover {
                    background-color: white;
                    color: black;
                }
            }

            a.contact {
                color: black;
                background: #FBC22B;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 60px;
                font-weight: 600;
                font-size: 20px;

                &:hover {
                    background-color: #f9b301;
                }

                img {
                    margin-right: 10px;
                }
            }
        }
    }

    .main-container {
        background: #0F0F0F;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.3);
        padding: 130px 90px 10px 90px;
        margin-top: 120px;
        width: 100%;

        h5 {
            font-weight: 600;
            font-size: 36px;
            margin-bottom: 30px;
        }

        .slogan {
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            margin-bottom: 30px;
        }

        .contacts {
            .row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom:20px;

                .method {
                    display: flex;
                    align-items: center;
                    width: 380px;

                    > div {
                        flex-direction: column;
                        display: flex;
                        justify-content: center;
                    }

                    a {
                        color: #f9b301;
                    }

                    img {
                        margin-right: 20px;
                    }

                    .title {
                        font-weight: 400;
                        font-size: 16px;
                        color: #999999;
                    }

                    .value {
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
            }
        }

        .bottom-text {
            margin-top: 60px;
            font-weight: 600;
            font-size: 14px;
        }
    }

    .vector {
        position: absolute;
        right: 25px;
        bottom: 30px;
        height: 60%;
    }

    @media only screen and (max-width: 1200px) {
        .top-container {
            flex-direction: column;
            height: fit-content;
            padding: 20px;

            .buttons {
                margin-top: 30px;
            }
        }

        .vector {
            display: none;
        }
    }
    @media only screen and (max-width: 890px) {
        .main-container {
            .contacts .row {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-bottom: 0;

                .method {
                    margin-bottom: 10px;
                    width: 100%;

                    .value {
                        white-space: pre-wrap;
                        word-break: break-all;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        margin-top: 10px;

        .top-container {
            display: none;
        }

        .main-container {
            padding: 30px;
        }
    }
}